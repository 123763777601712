<template>
    <div class="changelog-modal">
        <Header :title="title" @close="$emit('close')" />

        <div class="l-padded changelog-modal__content" v-html="changes"></div>

        <div
            v-if="showActions"
            class="l-padded l-inline l-spread l-center-v changelog-modal__actions"
        >
            <span>{{ $t('updateHint') }}</span>

            <base-button size="small" @click="handleUpdate">
                {{ $t('update') }}
            </base-button>
        </div>
    </div>
</template>

<script>
import marked from 'marked'

import BaseButton from './BaseButton'
import Header from './Header'

import changelogDe from 'raw-loader!../../assets/notifications/user_changelog_de.md'
import changelogEn from 'raw-loader!../../assets/notifications/user_changelog_en.md'
import changelogFr from 'raw-loader!../../assets/notifications/user_changelog_fr.md'
import changelogIt from 'raw-loader!../../assets/notifications/user_changelog_it.md'

const changelogMap = {
    de: changelogDe,
    en: changelogEn,
    fr: changelogFr,
    it: changelogIt,
}

export default {
    name: 'ChangelogModal',
    components: {
        BaseButton,
        Header,
    },
    props: {
        showActions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        title() {
            return `${this.$t('changelogTitle')}: ${
                process.env.VUE_APP_VERSION
            }`
        },
        changes() {
            return marked(changelogMap[this.$i18n.locale] || changelogMap.de, {
                sanitized: true,
            })
        },
    },
    methods: {
        handleUpdate() {
            window.location.reload()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "changelogTitle": "Updates for Version",
        "update": "Reload",
        "updateHint": "Reload the page to get the latest version"
    },
    "de": {
        "changelogTitle": "Updates für Version",
        "update": "Neu laden",
            "updateHint": "Laden Sie die Seite neu, um die neueste Version zu erhalten"
    },
    "it": {
        "changelogTitle": "Aggiornamenti per la versione",
        "update": "Ricarica",
        "updateHint": "Ricarica la pagina per ottenere l'ultima versione"
    }
}
</i18n>

<style lang="scss">
.changelog-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &__content {
        flex: 1 0 auto;

        p,
        h1,
        h2,
        h3 h4,
        h5,
        h6 {
            margin-bottom: 16px;
        }
    }

    &__actions {
        padding-top: 0;
        font-size: 14px;
    }
}
</style>
